.matrixGrid .ms-DetailsHeader {
  height: 300px;
}

.matrixGrid .ms-DetailsHeader span {
  text-orientation: sideways;
  writing-mode: vertical-lr;
  height: 300px;
  transform: scale(-1, -1);
  margin-right: -7px;  
}

.matrixGrid .ms-DetailsHeader span {
  text-orientation: sideways;
  writing-mode: vertical-lr;
  height: 300px;
  transform: scale(-1, -1);
  margin-right: -7px;  
}

.matrixGrid .ms-DetailsHeader .matrixTitle span {
  text-orientation: inherit;
  writing-mode: inherit;
  height: 300px;
  transform: inherit;
  margin-right: inherit;
}

.matrixGrid .ms-DetailsHeader .matrixTitle span .ms-DetailsHeader-cellName {
  padding-top:270px;
}
