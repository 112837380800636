.pin {
    width: 30px;
    height: 30px;
    font-weight: bold;
    font-size: large;
    color: rgb(202, 202, 202);
    border-radius: 50% 50% 50% 0;
    background: #bd1d1d;
    background-blend-mode: darken;
    position: absolute;
    transform: rotate(-45deg);
    text-align: center;
    text-shadow: rgb(202, 202, 202);
    text-indent: 5px;
    left: 50%;
    top: 50%;
    padding-top: 5px;
    padding-right: 5px;
    margin: -10px 10px 10px -10px;
}
