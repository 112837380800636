.listContent {
  height: 99%;
}

.componentWrapper {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.greenIcon {
  color: #5cbf7f;
}

.icon {
  color: #333333;
}

.listHeader {
  display: flex;
  height: 56px;
}
