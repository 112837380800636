.ms-DetailsRow-cellCheck{
  display: flex;
  align-items: center;
}

.fluentTableWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  width: 100%;
}

/* This is for the checkbox cell at the start of rows */
.ms-DetailsRow-cellCheck {
  display: flex;
  align-items: center;
}

.spinnerWrapper {
  margin-top: 40px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.column span {
  font-size: 12px;
  font-weight: normal;
  color: #ffa500;
}

[role='columnheader'].justify-content-center {
  & span.ms-DetailsHeader-cellTitle {
    justify-content: center;
  }
}

[role='gridcell'],
[role='rowheader'] {
  &.justify-content-center {
    justify-content: center;
  }
}

.justify-content-center {
  justify-content: center;
}

/* SCROLL BARS */
.ms-ScrollablePane.vgt-table-scroll-bar .ms-ScrollablePane--contentContainer {
    &::-webkit-scrollbar

{
    width: 15px;
    height: 16px;
}

&::-webkit-scrollbar-track {
    background-color: rgb(105 105 105 / 10%);
    border-radius: 5px;
}

&::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
    border-radius: 6px;
    border: 4px solid rgb(240 240 240);
}
}