.toolbar {
  height: 48px;
  background: transparent linear-gradient(177deg, #c37e7e 0%, #d13438 63%, #a4262c 100%) 0% 0% no-repeat padding-box;
  opacity: 1;
}

.toolbarContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
}

.toolbarLeft,
.toolbarCenter,
.toolbarRight {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex-wrap: nowrap;
}

.toolbarCenter {
  flex-grow: 1;
  flex-shrink: 2;
}

.toolbarSpacer {
  margin-left: 6.25px;
  margin-right: 6.25px;
}

.toolbarItem {
  border-radius: 0;
  height: 48px;
  display: flex;
  flex: 0 0 auto;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-weight: bold;
}

.toolbarText {
  font-size: 16px;
  color: #ffffff;
  user-select: none;
}

.toolbarIcon {
  width: 48px;
  height: 48px;
  color: #ffffff;
}

.toolbarIcon:hover {
  background-color: #00000029;
}

a,
a:link,
a:visited,
a:focus,
a:hover,
a:active {
  text-decoration: none;
}

.avatar {
  border-radius: 50%;
  width: 32px;
  height: 32px;
}

.noAvatar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: 0.1em solid #ffffff;
}

.notificationCounter {
  background-color: 'black'; 
  color: 'white'; 
  font-size: '8px';
  font-weight: 'normal';
  border-radius: '50%';
  text-align: 'center';
  padding: '1px 3px 3px 3px';
  position: 'relative';
  bottom: '7px';
  right: '13px'
}

:root {
  --toastify-color-progress-info:  hsl(7, 65%, 54%);
}
