.sleutels-en-meterstanden {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.sleutels-en-meterstanden .sleutels-en-meterstanden__tab {
    flex: 1 1 100%;
    display: flex;
    flex-direction: column;
}

.sleutels-en-meterstanden .sleutels-en-meterstanden__pivot-item {
    flex: 1 1 100%;
    display: flex;
    flex-direction: column;
}