@font-face {
  font-family: 'DataBalk-Icon-Font';
  src:  url('fonts/DataBalk-Icon-Font.eot?paizqw');
  src:  url('fonts/DataBalk-Icon-Font.eot?paizqw#iefix') format('embedded-opentype'),
  url('fonts/DataBalk-Icon-Font.woff2?paizqw') format('woff2'),
  url('fonts/DataBalk-Icon-Font.ttf?paizqw') format('truetype'),
  url('fonts/DataBalk-Icon-Font.woff?paizqw') format('woff'),
  url('fonts/DataBalk-Icon-Font.svg?paizqw#DataBalk-Icon-Font') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'DataBalk-Icon-Font';
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-Inspectic:before {
  content: "\eb51";
}
.icon-Aanbrengen:before {
  content: "\eb26";
}
.icon-Afdekken:before {
  content: "\eb27";
}
.icon-Afdoppen:before {
  content: "\eb28";
}
.icon-Afpersen:before {
  content: "\eb29";
}
.icon-Afstellen:before {
  content: "\eb2a";
}
.icon-Aftappen:before {
  content: "\eb2b";
}
.icon-Asbest:before {
  content: "\eb2c";
}
.icon-Controleren-en-Herstellen:before {
  content: "\eb2d";
}
.icon-Controleren:before {
  content: "\eb2e";
}
.icon-De-en-hermonteren:before {
  content: "\eb2f";
}
.icon-Doorspuiten:before {
  content: "\eb30";
}
.icon-Egaliseren:before {
  content: "\eb31";
}
.icon-Frezen:before {
  content: "\eb32";
}
.icon-Gangbaar-en-Sluitend-Maken:before {
  content: "\eb33";
}
.icon-Gronden:before {
  content: "\eb34";
}
.icon-Heraansluiten:before {
  content: "\eb35";
}
.icon-Herleggen:before {
  content: "\eb36";
}
.icon-Herstellen:before {
  content: "\eb37";
}
.icon-Ijken:before {
  content: "\eb38";
}
.icon-Inkorten:before {
  content: "\eb39";
}
.icon-Leveren:before {
  content: "\eb3a";
}
.icon-Loskoppelen:before {
  content: "\eb3b";
}
.icon-Ombouwen:before {
  content: "\eb3c";
}
.icon-Omwisselen:before {
  content: "\eb3d";
}
.icon-Ontkalken:before {
  content: "\eb3e";
}
.icon-Ontsmetten:before {
  content: "\eb3f";
}
.icon-Ontstoppen:before {
  content: "\eb40";
}
.icon-Opschonen:before {
  content: "\eb41";
}
.icon-Opstellen:before {
  content: "\eb42";
}
.icon-Regiewerk:before {
  content: "\eb43";
}
.icon-Schilderen:before {
  content: "\eb44";
}
.icon-Schoonmaken:before {
  content: "\eb45";
}
.icon-Tile:before {
  content: "\eb46";
}
.icon-Toeslag:before {
  content: "\eb47";
}
.icon-Uitrepareren:before {
  content: "\eb48";
}
.icon-Uitvoeren:before {
  content: "\eb49";
}
.icon-Vastzetten:before {
  content: "\eb4a";
}
.icon-Verlagen:before {
  content: "\eb4b";
}
.icon-Verplaatsen:before {
  content: "\eb4c";
}
.icon-Verslepen:before {
  content: "\eb4d";
}
.icon-Vervangen:before {
  content: "\eb4e";
}
.icon-Verwijderen:before {
  content: "\eb4f";
}
.icon-Wijzigen-Draairichting:before {
  content: "\eb50";
}
.icon-Vastgoedtabel:before {
  content: "\eb25";
}
.icon-Noord-North-N:before {
  content: "\eb1d";
}
.icon-Noordoost-Northeast-NO:before {
  content: "\eb1e";
}
.icon-Noordwest-Northwest-NW:before {
  content: "\eb1f";
}
.icon-Oost-East-O:before {
  content: "\eb20";
}
.icon-West-West-W:before {
  content: "\eb21";
}
.icon-Zuid-South-Z:before {
  content: "\eb22";
}
.icon-Zuidoost-Southeast-ZO:before {
  content: "\eb23";
}
.icon-Zuidwest-Southwest-ZW:before {
  content: "\eb24";
}
.icon-Gangkast-Trapkast:before {
  content: "\eb1c";
}
.icon-Aanbouw-uitbouw-aanbrengen:before {
  content: "\eaa9";
}
.icon-Aanrechtblad-aanpassen:before {
  content: "\eaaa";
}
.icon-Afvoer-afzuigkap-door-gevel-aanbrengen:before {
  content: "\eaab";
}
.icon-Afvoer-droger-door-gevel-aanbrengen:before {
  content: "\eaac";
}
.icon-Afvoer-watergeleidingen-aanpassen:before {
  content: "\eaad";
}
.icon-Afzuigkap-aanbrengen-bestaande-afvoer:before {
  content: "\eaae";
}
.icon-Airco-plaatsen:before {
  content: "\eaaf";
}
.icon-Badkamer-compleet-vervangen:before {
  content: "\eab0";
}
.icon-Badkamer-vergroten:before {
  content: "\eab1";
}
.icon-Badkamer-verplaatsen:before {
  content: "\eab2";
}
.icon-Bad-plaatsen:before {
  content: "\eab3";
}
.icon-Beloopbaar-maken-vloer:before {
  content: "\eab4";
}
.icon-Berging-schuur-vervangen:before {
  content: "\eab5";
}
.icon-Berging-schuur-verwijderen:before {
  content: "\eab6";
}
.icon-Bestaande-deuren-bekleden:before {
  content: "\eab7";
}
.icon-Bestrating-vervangen-uitbreiden:before {
  content: "\eab8";
}
.icon-Beugels-aanbrengen:before {
  content: "\eab9";
}
.icon-Beugels-douchezitje-aanbrengen:before {
  content: "\eaba";
}
.icon-Binnendeur-dichtmaken-of-wijzigen:before {
  content: "\eabb";
}
.icon-Binnendeuren-dorpels-vervangen:before {
  content: "\eabc";
}
.icon-Binnenmuur-verwijderen:before {
  content: "\eabd";
}
.icon-Boom-verwijderen:before {
  content: "\eabe";
}
.icon-Buitendeur-vervangen:before {
  content: "\eabf";
}
.icon-Buitenschilderwerk:before {
  content: "\eac0";
}
.icon-Buitenverlichting-aanbrengen:before {
  content: "\eac1";
}
.icon-Buitenwaterkraan-plaatsen:before {
  content: "\eac2";
}
.icon-Carport-aanbrengen:before {
  content: "\eac3";
}
.icon-Close-in-boiler-plaatsen:before {
  content: "\eac4";
}
.icon-CV-installatie-aanbrengen:before {
  content: "\eac5";
}
.icon-Dakisolatie-aanbrengen:before {
  content: "\eac6";
}
.icon-Dakkapel-plaatsen:before {
  content: "\eac7";
}
.icon-Dakraam-plaatsen:before {
  content: "\eac8";
}
.icon-Deuren-en-kozijnen-schilderen:before {
  content: "\eac9";
}
.icon-Deur-vervangen:before {
  content: "\eaca";
}
.icon-Dierenverblijf-plaatsen:before {
  content: "\eacb";
}
.icon-Douchebak-plaatsen:before {
  content: "\eacc";
}
.icon-Douchecabine-plaatsen:before {
  content: "\eacd";
}
.icon-Douchewand-plaatsen:before {
  content: "\eace";
}
.icon-Dubbelglas-plaatsen:before {
  content: "\eacf";
}
.icon-Elektra-in-berging-of-garage-aanleggen:before {
  content: "\ead0";
}
.icon-Elektraleiding-aanpassen:before {
  content: "\ead1";
}
.icon-Extra-elektriciteitgroep-aanbrengen:before {
  content: "\ead2";
}
.icon-Extra-kastjes-aanbrengen:before {
  content: "\ead3";
}
.icon-Extra-toilet-plaatsen:before {
  content: "\ead4";
}
.icon-Extra-vertrek-maken:before {
  content: "\ead5";
}
.icon-Fonteintje-plaatsen:before {
  content: "\ead6";
}
.icon-Fonteintje-verwijderen:before {
  content: "\ead7";
}
.icon-Frontjes-schilderen:before {
  content: "\ead8";
}
.icon-Gaskraan-verplaatsen:before {
  content: "\ead9";
}
.icon-Gasleiding-aanpassen:before {
  content: "\eada";
}
.icon-Gasleiding-voor-gashaard-aanleggen:before {
  content: "\eadb";
}
.icon-Glaswand-plaatsen:before {
  content: "\eadc";
}
.icon-Granol-korrelgrootte-groter-3m:before {
  content: "\eadd";
}
.icon-Granol-korrelgrootte-kleiner-3m:before {
  content: "\eade";
}
.icon-Hangend-toilet-aanbrengen:before {
  content: "\eadf";
}
.icon-Hang-en-sluitwerk-aanbrengen:before {
  content: "\eae0";
}
.icon-Houten-wand-plafondbetimmering:before {
  content: "\eae1";
}
.icon-Inbouwapparatuur-plaatsen:before {
  content: "\eae2";
}
.icon-Inbouwreservoir-voor-toilet:before {
  content: "\eae3";
}
.icon-Isolatieglas-plaatsen:before {
  content: "\eae4";
}
.icon-Kachel-plaatsen:before {
  content: "\eae5";
}
.icon-Kamerthermostaat-vervangen:before {
  content: "\eae6";
}
.icon-Kastenwand-aanbrengen:before {
  content: "\eae7";
}
.icon-Kastenwand-verwijderen:before {
  content: "\eae8";
}
.icon-Kattenluikje-aanbrengen:before {
  content: "\eae9";
}
.icon-Keukenblok-vervangen:before {
  content: "\eaea";
}
.icon-Krachtstroom-aanbrengen:before {
  content: "\eaeb";
}
.icon-Mechanische-ventilatie-aanbrengen:before {
  content: "\eaec";
}
.icon-Open-haard-aanbrengen:before {
  content: "\eaed";
}
.icon-Overige-isolatie-aanbrengen:before {
  content: "\eaee";
}
.icon-Overkapping-of-serre-aanbrengen:before {
  content: "\eaef";
}
.icon-Parket-en-laminaat-aanbrengen:before {
  content: "\eaf0";
}
.icon-Plafondafwerking-aanbrengen:before {
  content: "\eaf1";
}
.icon-Plavuizen-aanbrengen:before {
  content: "\eaf2";
}
.icon-Radiator-plaatsen:before {
  content: "\eaf3";
}
.icon-Radiator-verplaatsen:before {
  content: "\eaf4";
}
.icon-Radiator-verwijderen:before {
  content: "\eaf5";
}
.icon-Riolering-aanpassen:before {
  content: "\eaf6";
}
.icon-Rookmelder-aanbrengen:before {
  content: "\eaf7";
}
.icon-Schilderen-tegelwerk:before {
  content: "\eaf8";
}
.icon-Schoorsteenmantel-verwijderen:before {
  content: "\eaf9";
}
.icon-Schotel-of-antenne-plaatsen:before {
  content: "\eafa";
}
.icon-Schuifpui-aanbrengen:before {
  content: "\eafb";
}
.icon-Schuifwand-plaatsen:before {
  content: "\eafc";
}
.icon-Schutting-achtertuin-2-meter-hoogte:before {
  content: "\eafd";
}
.icon-Schutting-voortuin-1-meter-hoogte:before {
  content: "\eafe";
}
.icon-Slimme-thermostaat-plaatsen:before {
  content: "\eaff";
}
.icon-Spouwisolatie-aanbrengen:before {
  content: "\eb00";
}
.icon-Steenstrips-ornamenten-aanbrengen:before {
  content: "\eb01";
}
.icon-Thermostaatkraan-op-radiator-plaatsen:before {
  content: "\eb02";
}
.icon-Thermostaatkraan-plaatsen:before {
  content: "\eb03";
}
.icon-Tochtwering-en-tochtstrips:before {
  content: "\eb04";
}
.icon-Toilet-compleet-vervangen:before {
  content: "\eb05";
}
.icon-Toog-plaatsen:before {
  content: "\eb06";
}
.icon-Tuinhuisje-van-hout-plaatsen:before {
  content: "\eb07";
}
.icon-Tuinverlichting-aanbrengen:before {
  content: "\eb08";
}
.icon-Vaste-trap-aanbrengen:before {
  content: "\eb09";
}
.icon-Vensterbank-aanbrengen:before {
  content: "\eb0a";
}
.icon-Verhoogde-toiletpot-aanbrengen:before {
  content: "\eb0b";
}
.icon-Videofoon-aanleggen:before {
  content: "\eb0c";
}
.icon-Vijver-aanleggen:before {
  content: "\eb0d";
}
.icon-Vlizotrap-aanbrengen:before {
  content: "\eb0e";
}
.icon-Vloerisolatie-aanbrengen:before {
  content: "\eb0f";
}
.icon-Vloerverwarming-aanleggen:before {
  content: "\eb10";
}
.icon-Wandcontactdoze-wijzigen:before {
  content: "\eb11";
}
.icon-Wandtegels-vloertegels:before {
  content: "\eb12";
}
.icon-Warm-water-aansluiten-op-CV:before {
  content: "\eb13";
}
.icon-Wasmachine-aansluiting:before {
  content: "\eb14";
}
.icon-Wasmachine-aansluiting-verwijderen:before {
  content: "\eb15";
}
.icon-Wastafel-plaatsen:before {
  content: "\eb16";
}
.icon-Wastafel-verwijderen:before {
  content: "\eb17";
}
.icon-Water-en-CV-leidingen-isoleren:before {
  content: "\eb18";
}
.icon-Waterleiding-aanpassen:before {
  content: "\eb19";
}
.icon-Zonnepanelen-aanbrengen:before {
  content: "\eb1a";
}
.icon-Zonnescherm-aanbrengen:before {
  content: "\eb1b";
}
.icon-Lengte:before {
  content: "\eaa3";
}
.icon-Hoogte:before {
  content: "\eaa4";
}
.icon-Breedte:before {
  content: "\eaa5";
}
.icon-Handzender:before {
  content: "\eaa6";
}
.icon-Sleutel:before {
  content: "\eaa7";
}
.icon-Tag-elektronische-sleutel:before {
  content: "\eaa8";
}
.icon-Alle-inspecties:before {
  content: "\ea96";
}
.icon-Buitenberging:before {
  content: "\ea97";
}
.icon-Corporatie-taken:before {
  content: "\ea98";
}
.icon-Foto-toevoegen:before {
  content: "\ea99";
}
.icon-Foto:before {
  content: "\ea9a";
}
.icon-Huurder-taken:before {
  content: "\ea9b";
}
.icon-Huurder-huurder:before {
  content: "\ea9c";
}
.icon-Inspecties:before {
  content: "\ea9d";
}
.icon-Pin:before {
  content: "\ea9e";
}
.icon-Plattegrond:before {
  content: "\ea9f";
}
.icon-Starten:before {
  content: "\eaa0";
}
.icon-Woning:before {
  content: "\eaa1";
}
.icon-ZAV:before {
  content: "\eaa2";
}
.icon-WMO:before {
  content: "\ea95";
}
.icon-Aan-de-gevel:before {
  content: "\e91b";
}
.icon-Aanrecht-Blad:before {
  content: "\e91c";
}
.icon-Aanrecht-Gootsteen:before {
  content: "\e91d";
}
.icon-Aanrecht-Kitwerk:before {
  content: "\e91e";
}
.icon-Aanrecht-Sifon:before {
  content: "\e91f";
}
.icon-Aanrecht:before {
  content: "\e920";
}
.icon-Aardlekschakelaar:before {
  content: "\e921";
}
.icon-Afvoer-Doucheput:before {
  content: "\e922";
}
.icon-Afvoer-Gootsteen:before {
  content: "\e923";
}
.icon-Afvoer-Schrobput:before {
  content: "\e924";
}
.icon-Afvoer-Vaatwasmachine:before {
  content: "\e925";
}
.icon-Afvoer-Wasmachine:before {
  content: "\e926";
}
.icon-Afvoer-Wastafel-of-fontein:before {
  content: "\e927";
}
.icon-Afvoer-badkamer-Badafvoer:before {
  content: "\e928";
}
.icon-Afvoer-toilet-Toiletafvoer:before {
  content: "\e929";
}
.icon-Afvoer:before {
  content: "\e92a";
}
.icon-Afzuigkap-Filter:before {
  content: "\e92b";
}
.icon-Afzuigkap-Met-motor:before {
  content: "\e92c";
}
.icon-Afzuigkap-Zonder-motor:before {
  content: "\e92d";
}
.icon-Afzuigkap:before {
  content: "\e92e";
}
.icon-Algemeen-Inspectie:before {
  content: "\e92f";
}
.icon-Algemeen-locatie:before {
  content: "\e930";
}
.icon-Aluminium:before {
  content: "\e931";
}
.icon-Antenne-en-schotel:before {
  content: "\e932";
}
.icon-Armatuur:before {
  content: "\e933";
}
.icon-Automatische-deur:before {
  content: "\e934";
}
.icon-Bad-Douchekraan:before {
  content: "\e935";
}
.icon-Badkamer-Doucheruimte-met-toilet-locatie:before {
  content: "\e936";
}
.icon-Badkamer-Doucheruimte-locatie:before {
  content: "\e937";
}
.icon-Badkuip-Afvoer:before {
  content: "\e938";
}
.icon-Badkuip-Badkuip:before {
  content: "\e939";
}
.icon-Badkuip-Kitwerk:before {
  content: "\e93a";
}
.icon-Badkuip:before {
  content: "\e93b";
}
.icon-Balkon-locatie:before {
  content: "\e93c";
}
.icon-Balkonhek-of-scheidingswand:before {
  content: "\e93d";
}
.icon-Balkonhek:before {
  content: "\e93e";
}
.icon-Balkonkast-Slot:before {
  content: "\e93f";
}
.icon-Balkonkast-Deur:before {
  content: "\e940";
}
.icon-Balkonkast:before {
  content: "\e941";
}
.icon-Ballustrades-en-leuningen:before {
  content: "\e942";
}
.icon-Bankje-in-lift:before {
  content: "\e943";
}
.icon-Bedrading:before {
  content: "\e944";
}
.icon-Bel-en-intercom:before {
  content: "\e945";
}
.icon-Beplanting-2:before {
  content: "\e946";
}
.icon-Beplanting:before {
  content: "\e947";
}
.icon-Berging-losstaand-in-tuin-locatie:before {
  content: "\e948";
}
.icon-Bergruimte-inpandig-locatie:before {
  content: "\e949";
}
.icon-Bestrating-Stoeptegels:before {
  content: "\e94b";
}
.icon-Bestrating-2:before {
  content: "\e94c";
}
.icon-Bestrating:before {
  content: "\e94d";
}
.icon-Beton:before {
  content: "\e94e";
}
.icon-Bijkeuken-locatie:before {
  content: "\e94f";
}
.icon-Binnendeur:before {
  content: "\e950";
}
.icon-Blusmiddelen-Brandslang:before {
  content: "\e951";
}
.icon-Blusmiddelen-Hand:before {
  content: "\e952";
}
.icon-Blusmiddelen-Sprinkler:before {
  content: "\e953";
}
.icon-Blusmiddelen:before {
  content: "\e954";
}
.icon-Boiler:before {
  content: "\e955";
}
.icon-Brandgangverlichting:before {
  content: "\e956";
}
.icon-Brandmeld-blusinstallatie:before {
  content: "\e957";
}
.icon-Brandmelder-installatie:before {
  content: "\e958";
}
.icon-Brandpreventie:before {
  content: "\e959";
}
.icon-Brandtrap:before {
  content: "\e95a";
}
.icon-Brievenbus:before {
  content: "\e95b";
}
.icon-Buitendeur:before {
  content: "\e95c";
}
.icon-Buitengevel-locatie:before {
  content: "\e95d";
}
.icon-Buitenkraan:before {
  content: "\e95e";
}
.icon-Camerabeveiliging:before {
  content: "\e95f";
}
.icon-Camerabewaking-locatie:before {
  content: "\e960";
}
.icon-Carport-locatie:before {
  content: "\e961";
}
.icon-Containerruimte:before {
  content: "\e962";
}
.icon-CV-installatie:before {
  content: "\e963";
}
.icon-Dak-2-locatie:before {
  content: "\e965";
}
.icon-Dak:before {
  content: "\e966";
}
.icon-Dakbedekking-Bitumen:before {
  content: "\e967";
}
.icon-Dakbedekking-Dakpannen:before {
  content: "\e968";
}
.icon-Dakbedekking-Golfplaten:before {
  content: "\e969";
}
.icon-Dakbedekking-Overige:before {
  content: "\e96a";
}
.icon-Dakbedekking:before {
  content: "\e96b";
}
.icon-Dakgoot:before {
  content: "\e96c";
}
.icon-Dakkapel:before {
  content: "\e96d";
}
.icon-Dakraam:before {
  content: "\e96e";
}
.icon-Dakterras:before {
  content: "\e96f";
}
.icon-Deur:before {
  content: "\e970";
}
.icon-Deuren-ramen-en-kozijnen:before {
  content: "\e971";
}
.icon-Douchebak-Douchebak:before {
  content: "\e972";
}
.icon-Douchebak-Kitwerk:before {
  content: "\e973";
}
.icon-Douchebak-Afvoer:before {
  content: "\e974";
}
.icon-Douchebak:before {
  content: "\e975";
}
.icon-Douchekop:before {
  content: "\e976";
}
.icon-Doucheput:before {
  content: "\e977";
}
.icon-Douchescherm:before {
  content: "\e978";
}
.icon-Doucheslang:before {
  content: "\e979";
}
.icon-Elektra-en-verlichting:before {
  content: "\e97a";
}
.icon-Entreehal-Ingang-locatie:before {
  content: "\e97b";
}
.icon-Dak-luifel:before {
  content: "\e97c";
}
.icon-Fietsenstalling:before {
  content: "\e97d";
}
.icon-Fontein-Fontein:before {
  content: "\e97e";
}
.icon-Fontein-Kitwerk:before {
  content: "\e97f";
}
.icon-Fontein-Kraan:before {
  content: "\e980";
}
.icon-Fontein-Sifon:before {
  content: "\e981";
}
.icon-Fontein-Gescheurd:before {
  content: "\e982";
}
.icon-Fontein-Stop:before {
  content: "\e983";
}
.icon-Fontein:before {
  content: "\e984";
}
.icon-Galerij-gang-locatie:before {
  content: "\e985";
}
.icon-Galerijhek:before {
  content: "\e986";
}
.icon-Gangkast-Trapkast-locatie:before {
  content: "\e987";
}
.icon-Garage-locatie:before {
  content: "\e988";
}
.icon-Garagedeur-Elektr:before {
  content: "\e989";
}
.icon-Garagedeur-Elektrisch-Garagedeur:before {
  content: "\e98a";
}
.icon-Garagedeur-Elektrisch-2:before {
  content: "\e98b";
}
.icon-Garagedeur-Elektrisch:before {
  content: "\e98c";
}
.icon-Garagedeur-Hand-Toegangspasje:before {
  content: "\e98d";
}
.icon-Garagedeur-Handbediend:before {
  content: "\e98e";
}
.icon-Garagedeur-Handmatig-Garagedeur:before {
  content: "\e98f";
}
.icon-Gas-Leiding-Kraan:before {
  content: "\e990";
}
.icon-Gaskraan:before {
  content: "\e991";
}
.icon-Gasleiding:before {
  content: "\e992";
}
.icon-Gasslang:before {
  content: "\e993";
}
.icon-Geiser:before {
  content: "\e994";
}
.icon-Gevelbekleding-Buitengevel:before {
  content: "\e995";
}
.icon-Gevelbekleding-Stucwerk:before {
  content: "\e996";
}
.icon-Gevelbekleding-Voegwerk:before {
  content: "\e997";
}
.icon-Gevelbekleding:before {
  content: "\e998";
}
.icon-Glas:before {
  content: "\e999";
}
.icon-Glijstang:before {
  content: "\e99a";
}
.icon-Graffiti-Geen-Kwetsende:before {
  content: "\e99b";
}
.icon-Graffiti-Kwetsende-teksten:before {
  content: "\e99c";
}
.icon-Graffiti:before {
  content: "\e99d";
}
.icon-Groen-bomen-locatie:before {
  content: "\e99e";
}
.icon-Groepenkast:before {
  content: "\e99f";
}
.icon-Grond:before {
  content: "\e9a0";
}
.icon-Hal-Gang-locatie:before {
  content: "\e9a1";
}
.icon-Handdouche:before {
  content: "\e9a2";
}
.icon-Hang-en-sluitwerk:before {
  content: "\e9a3";
}
.icon-Hard-glas:before {
  content: "\e9a4";
}
.icon-Hek-schutting:before {
  content: "\e9a5";
}
.icon-Hekwerk-of-schutting:before {
  content: "\e9a6";
}
.icon-Hoofdafsluiter-water-stopkraan:before {
  content: "\e9a7";
}
.icon-Hout:before {
  content: "\e9a8";
}
.icon-Huistelefoon-Intercom:before {
  content: "\e9a9";
}
.icon-Hydrofoor-drukverhoging:before {
  content: "\e9aa";
}
.icon-Inbouwapparatuur:before {
  content: "\e9ab";
}
.icon-Inbouwkast:before {
  content: "\e9ac";
}
.icon-Infrarood-panelen:before {
  content: "\e9ad";
}
.icon-Ingebouwde-kast-meer-2-locatiem2:before {
  content: "\e9ae";
}
.icon-Kamerthermostaat:before {
  content: "\e9af";
}
.icon-Kast-Deur:before {
  content: "\e9b0";
}
.icon-Kast-Handgreep:before {
  content: "\e9b1";
}
.icon-Kast-Plank:before {
  content: "\e9b2";
}
.icon-Kast-Slot:before {
  content: "\e9b3";
}
.icon-Kast:before {
  content: "\e9b4";
}
.icon-Kelder-locatie:before {
  content: "\e9b5";
}
.icon-Keuken-locatie:before {
  content: "\e9b6";
}
.icon-Keukenblok:before {
  content: "\e9b7";
}
.icon-Keukenkast-Deur:before {
  content: "\e9b8";
}
.icon-Keukenkast-Handgreep:before {
  content: "\e9b9";
}
.icon-Keukenkast-Scharnier:before {
  content: "\e9ba";
}
.icon-Keukenkast:before {
  content: "\e9bb";
}
.icon-Keukenlade-Handgreep:before {
  content: "\e9bc";
}
.icon-Keukenlade-lade:before {
  content: "\e9bd";
}
.icon-Keukenlade:before {
  content: "\e9be";
}
.icon-Koper:before {
  content: "\e9bf";
}
.icon-Kozijn-binnen:before {
  content: "\e9c0";
}
.icon-Kozijn-buiten:before {
  content: "\e9c1";
}
.icon-Kraan:before {
  content: "\e9c2";
}
.icon-Kruipluik:before {
  content: "\e9c3";
}
.icon-Kruipruimte-2:before {
  content: "\e9c4";
}
.icon-Kruipruimte:before {
  content: "\e9c5";
}
.icon-Kunststof:before {
  content: "\e9c6";
}
.icon-Lamp:before {
  content: "\e9c7";
}
.icon-Leiding-van-verwarming:before {
  content: "\e9c8";
}
.icon-Elektra-noodverlichting:before {
  content: "\e9c9";
}
.icon-Lichtkoepel-lichtstraat-dakluik:before {
  content: "\e9ca";
}
.icon-Lift-2:before {
  content: "\e9cb";
}
.icon-Lift-3:before {
  content: "\e9cc";
}
.icon-Lift:before {
  content: "\e9cd";
}
.icon-Liftdeur:before {
  content: "\e9ce";
}
.icon-Lood:before {
  content: "\e9cf";
}
.icon-Materiaal-onbekend:before {
  content: "\e9d0";
}
.icon-Mechanishe-ventilatie:before {
  content: "\e9d1";
}
.icon-Metaal:before {
  content: "\e9d2";
}
.icon-Meterkast-Deur:before {
  content: "\e9d3";
}
.icon-Meterkast-Slot:before {
  content: "\e9d4";
}
.icon-Meterkast-Vloer:before {
  content: "\e9d5";
}
.icon-Meterkast-2:before {
  content: "\e9d6";
}
.icon-Meterkast-extern:before {
  content: "\e9d7";
}
.icon-Meterkast:before {
  content: "\e9d8";
}
.icon-Meterput:before {
  content: "\e9d9";
}
.icon-Muur-Muur:before {
  content: "\e9da";
}
.icon-Muur-Plint:before {
  content: "\e9db";
}
.icon-Muur-Stucwerk:before {
  content: "\e9dc";
}
.icon-Muur-Wandtegel:before {
  content: "\e9dd";
}
.icon-Muur:before {
  content: "\e9de";
}
.icon-Natuursteen:before {
  content: "\e9df";
}
.icon-Ongedierte-Bijen:before {
  content: "\e9e0";
}
.icon-Ongedierte-Boktorren:before {
  content: "\e9e1";
}
.icon-Ongedierte-Houtwormen:before {
  content: "\e9e2";
}
.icon-Ongedierte-Kakkarlakken:before {
  content: "\e9e3";
}
.icon-Ongedierte-Kevers:before {
  content: "\e9e4";
}
.icon-Ongedierte-Mieren:before {
  content: "\e9e5";
}
.icon-Ongedierte-Muizen:before {
  content: "\e9e6";
}
.icon-Ongedierte-Overig:before {
  content: "\e9e7";
}
.icon-Ongedierte-ratten:before {
  content: "\e9e8";
}
.icon-Ongedierte-Vlooien:before {
  content: "\e9e9";
}
.icon-Ongedierte-Wandluizen:before {
  content: "\e9ea";
}
.icon-Ongedierte-Wespen:before {
  content: "\e9eb";
}
.icon-Ongedierte-Zilvervisjes:before {
  content: "\e9ec";
}
.icon-Ongedierte-2:before {
  content: "\e9ed";
}
.icon-Ongedierte:before {
  content: "\e9ee";
}
.icon-Ontmoetingsruimte:before {
  content: "\e9ef";
}
.icon-Overige-2:before {
  content: "\e9f0";
}
.icon-Overige-ruimte:before {
  content: "\e9f1";
}
.icon-Overige:before {
  content: "\e9f2";
}
.icon-Overloop:before {
  content: "\e9f3";
}
.icon-Pad-Brandgang-locatie:before {
  content: "\e9f4";
}
.icon-Parkeerbeugel:before {
  content: "\e9f5";
}
.icon-Parkeerplaats-buiten:before {
  content: "\e9f6";
}
.icon-Parkeerplaats-overdekt-Kelder-garage:before {
  content: "\e9f7";
}
.icon-Parkeren:before {
  content: "\e9f8";
}
.icon-Plafond:before {
  content: "\e9f9";
}
.icon-Plafonds-muren-en-vloeren:before {
  content: "\e9fa";
}
.icon-Plint:before {
  content: "\e9fb";
}
.icon-Poort:before {
  content: "\e9fc";
}
.icon-Postkast-Deur:before {
  content: "\e9fd";
}
.icon-Postkast-Naamplaatje:before {
  content: "\e9fe";
}
.icon-Postkast-Slot:before {
  content: "\e9ff";
}
.icon-Postkast:before {
  content: "\ea00";
}
.icon-Privacyscherm-tuin-en-balkon:before {
  content: "\ea01";
}
.icon-Put:before {
  content: "\ea02";
}
.icon-Raam-binnen:before {
  content: "\ea03";
}
.icon-Raam-buiten:before {
  content: "\ea04";
}
.icon-Radiator-Knop:before {
  content: "\ea05";
}
.icon-Radiator-Radiator:before {
  content: "\ea06";
}
.icon-Radiator-Warmtemeter:before {
  content: "\ea07";
}
.icon-Radiator:before {
  content: "\ea08";
}
.icon-Regenpijp:before {
  content: "\ea09";
}
.icon-Riool-en-afvoer:before {
  content: "\ea0a";
}
.icon-Rioolbuis:before {
  content: "\ea0b";
}
.icon-Roestvast-staal:before {
  content: "\ea0c";
}
.icon-Rookgasafvoer:before {
  content: "\ea0d";
}
.icon-Rookmelder-Batterij:before {
  content: "\ea0e";
}
.icon-Rookmelder-Netspanning:before {
  content: "\ea0f";
}
.icon-Rookmelder:before {
  content: "\ea10";
}
.icon-Sanitair:before {
  content: "\ea11";
}
.icon-Schakelaar-Afdekplaat:before {
  content: "\ea12";
}
.icon-Schakelaar-Schakelaar:before {
  content: "\ea13";
}
.icon-Schakelaar:before {
  content: "\ea14";
}
.icon-Scheidingswand:before {
  content: "\ea15";
}
.icon-Schoorsteen-Bolrooster:before {
  content: "\ea16";
}
.icon-Schoorsteen-Metselwerk-2:before {
  content: "\ea17";
}
.icon-Schoorsteen-Metselwerk:before {
  content: "\ea18";
}
.icon-Schoorsteen:before {
  content: "\ea19";
}
.icon-Schuifdeur-binnen-Auto:before {
  content: "\ea1a";
}
.icon-Schuifdeur-binnen-Hand:before {
  content: "\ea1b";
}
.icon-Schuifdeur-binnen:before {
  content: "\ea1c";
}
.icon-Schuifdeur-buiten-Auto:before {
  content: "\ea1d";
}
.icon-Schuifdeur-buiten-Hand:before {
  content: "\ea1e";
}
.icon-Schuifdeur-buiten:before {
  content: "\ea1f";
}
.icon-Schuifpui-Algemeen:before {
  content: "\ea20";
}
.icon-Schuifpui:before {
  content: "\ea21";
}
.icon-Schuur:before {
  content: "\ea22";
}
.icon-Scootmobielruimte:before {
  content: "\ea23";
}
.icon-Serre:before {
  content: "\ea24";
}
.icon-Slaapkamer:before {
  content: "\ea25";
}
.icon-Slagboom-Afstandbediening:before {
  content: "\ea26";
}
.icon-Slagboom-Slagboom:before {
  content: "\ea27";
}
.icon-Slagboom-Toegangspasje:before {
  content: "\ea28";
}
.icon-Slagboom:before {
  content: "\ea29";
}
.icon-Spiegel:before {
  content: "\ea2a";
}
.icon-Staal:before {
  content: "\ea2b";
}
.icon-Stopcontact-Afdekplaat:before {
  content: "\ea2c";
}
.icon-Stopcontact-Stopcontact:before {
  content: "\ea2d";
}
.icon-Stopcontact-met-schakelaar:before {
  content: "\ea2f";
}
.icon-Stopcontact-schakelaar---Schakelaar:before {
  content: "\ea30";
}
.icon-Stopcontact-schakelaar---Stopcontact:before {
  content: "\ea31";
}
.icon-Stopcontact:before {
  content: "\ea32";
}
.icon-Stortbak-Kraan:before {
  content: "\ea33";
}
.icon-Stortbak---Stortbak:before {
  content: "\ea34";
}
.icon-Stortbak-Trekker-of-drukknop:before {
  content: "\ea35";
}
.icon-Stortbak:before {
  content: "\ea36";
}
.icon-Stortkoker:before {
  content: "\ea37";
}
.icon-Stortkokers:before {
  content: "\ea38";
}
.icon-Straatkolken:before {
  content: "\ea39";
}
.icon-Stroomtoevoer:before {
  content: "\ea3a";
}
.icon-Technishe-ruimte:before {
  content: "\ea3b";
}
.icon-Toegangshek-Afstandbediening:before {
  content: "\ea3c";
}
.icon-Toegangshek-Hek:before {
  content: "\ea3d";
}
.icon-Toegangshek-Toegangspasje:before {
  content: "\ea3e";
}
.icon-Toegangshek:before {
  content: "\ea3f";
}
.icon-Toiletpot-Closetsok:before {
  content: "\ea40";
}
.icon-Toiletpot-Gescheurd:before {
  content: "\ea41";
}
.icon-Toiletpot-Kitwerk:before {
  content: "\ea42";
}
.icon-Toiletpot-Toiletbril:before {
  content: "\ea43";
}
.icon-Toiletpot:before {
  content: "\ea45";
}
.icon-Toiletruimte:before {
  content: "\ea46";
}
.icon-Trap-en-trapleuning:before {
  content: "\ea47";
}
.icon-Trap:before {
  content: "\ea48";
}
.icon-Traphek:before {
  content: "\ea49";
}
.icon-Trapleuning:before {
  content: "\ea4a";
}
.icon-Trappenhuis:before {
  content: "\ea4b";
}
.icon-Traptrede:before {
  content: "\ea4c";
}
.icon-Trekschakelaar-Schakelaar:before {
  content: "\ea4d";
}
.icon-Trekschakelaar-Verlichting:before {
  content: "\ea4e";
}
.icon-Trekschakelaar-Wasmachine:before {
  content: "\ea4f";
}
.icon-Trekschakelaar:before {
  content: "\ea50";
}
.icon-Tuin:before {
  content: "\ea51";
}
.icon-Tuinkast:before {
  content: "\ea52";
}
.icon-Tuinmuur:before {
  content: "\ea53";
}
.icon-TV-Telefoon-Internet-aansluiting:before {
  content: "\ea54";
}
.icon-Vaatwasserkraan:before {
  content: "\ea55";
}
.icon-Valpijp:before {
  content: "\ea56";
}
.icon-Vensterbank-binnen:before {
  content: "\ea57";
}
.icon-Vensterbank-buiten:before {
  content: "\ea58";
}
.icon-Ventilatie-Ventilator:before {
  content: "\ea59";
}
.icon-Ventilatie-Centrale-afzuig:before {
  content: "\ea5a";
}
.icon-Ventilatie-mechanisch:before {
  content: "\ea5b";
}
.icon-Ventilatie:before {
  content: "\ea5c";
}
.icon-Ventilatiekanaal-ventielen:before {
  content: "\ea5d";
}
.icon-Ventilatierooster-In-muur:before {
  content: "\ea5e";
}
.icon-Ventilatierooster-Raam:before {
  content: "\ea5f";
}
.icon-Ventilatierooster:before {
  content: "\ea60";
}
.icon-Verwarming:before {
  content: "\ea61";
}
.icon-Vlaggemast:before {
  content: "\ea62";
}
.icon-Vlaggenmast-Houder:before {
  content: "\ea63";
}
.icon-Vlaggenmast-Mast:before {
  content: "\ea64";
}
.icon-Vlaggenmast-Vlag-2:before {
  content: "\ea65";
}
.icon-Vlaggenmast-Vlag:before {
  content: "\ea66";
}
.icon-Vlaggenmast:before {
  content: "\ea67";
}
.icon-Vlizotrap-Scharnier:before {
  content: "\ea68";
}
.icon-Vlizotrap-Trap:before {
  content: "\ea69";
}
.icon-Vlizotrap:before {
  content: "\ea6a";
}
.icon-Vloer-Kelder:before {
  content: "\ea6b";
}
.icon-Vloer-Vloertegels:before {
  content: "\ea6d";
}
.icon-Vloer-Afvoerput:before {
  content: "\ea6e";
}
.icon-Vloer-wand-plafond-van-lift:before {
  content: "\ea6f";
}
.icon-Vloer:before {
  content: "\ea70";
}
.icon-Vloerverwarming:before {
  content: "\ea71";
}
.icon-Voertuigingang-locatie:before {
  content: "\ea72";
}
.icon-Voordeurbel:before {
  content: "\ea73";
}
.icon-Vouwdeur-binnen-Handbediend:before {
  content: "\ea74";
}
.icon-Vouwdeur-binnen-Elektrisch:before {
  content: "\ea75";
}
.icon-Vouwdeur-binnen:before {
  content: "\ea76";
}
.icon-Warmteopwekking-centraal:before {
  content: "\ea77";
}
.icon-Warmtepomp:before {
  content: "\ea78";
}
.icon-Warmwatervoorziening-centraal:before {
  content: "\ea79";
}
.icon-Wasmachinekraan:before {
  content: "\ea7a";
}
.icon-Wasruimte1:before {
  content: "\ea7b";
}
.icon-Wastafel-Kitwerk:before {
  content: "\ea7c";
}
.icon-Wastafel-Planchet:before {
  content: "\ea7d";
}
.icon-Wastafel-Sifon:before {
  content: "\ea7e";
}
.icon-Wastafel-Spiegel:before {
  content: "\ea7f";
}
.icon-Wastafel-Stop:before {
  content: "\ea80";
}
.icon-Wastafel-Wastafel:before {
  content: "\ea81";
}
.icon-Wastafel:before {
  content: "\ea82";
}
.icon-Water-leiding-en-kraan:before {
  content: "\ea83";
}
.icon-Waterleiding-Aftapkraan:before {
  content: "\ea84";
}
.icon-Waterleiding-2:before {
  content: "\ea85";
}
.icon-Waterleiding:before {
  content: "\ea86";
}
.icon-Watermeter-Lekkage:before {
  content: "\ea87";
}
.icon-Watermeter-Lekkage-tot-en:before {
  content: "\ea88";
}
.icon-Watermeter:before {
  content: "\ea89";
}
.icon-Woonkamer1:before {
  content: "\ea8a";
}
.icon-WTW-Instellatie:before {
  content: "\ea8b";
}
.icon-Zeepbakje:before {
  content: "\ea8d";
}
.icon-Zink:before {
  content: "\ea8e";
}
.icon-Zitbanken-en-speelwerktuigen:before {
  content: "\ea8f";
}
.icon-Zolder-Vliering:before {
  content: "\ea90";
}
.icon-Zonnepaneel:before {
  content: "\ea92";
}
.icon-Zonwering-of-zonneschermen:before {
  content: "\ea94";
}
.icon-Achtertuin:before {
  content: "\e900";
}
.icon-Badkamer:before {
  content: "\e901";
}
.icon-Balkon-dakterras:before {
  content: "\e902";
}
.icon-Bijkeuken:before {
  content: "\e903";
}
.icon-Binnenberging:before {
  content: "\e904";
}
.icon-Carport:before {
  content: "\e905";
}
.icon-Dakterras1:before {
  content: "\e906";
}
.icon-Garage1:before {
  content: "\e907";
}
.icon-Hal:before {
  content: "\e908";
}
.icon-Inbouwkast1:before {
  content: "\e909";
}
.icon-Kelder1:before {
  content: "\e90a";
}
.icon-Keuken1:before {
  content: "\e90b";
}
.icon-Loggia:before {
  content: "\e90c";
}
.icon-Patio:before {
  content: "\e90e";
}
.icon-Serre1:before {
  content: "\e90f";
}
.icon-Slaapkamer-zolder:before {
  content: "\e910";
}
.icon-Slaapkamer1:before {
  content: "\e911";
}
.icon-Tochtportaal:before {
  content: "\e912";
}
.icon-Toilet:before {
  content: "\e913";
}
.icon-Voortuin:before {
  content: "\e914";
}
.icon-Voorzolder:before {
  content: "\e915";
}
.icon-Wasruimte:before {
  content: "\e916";
}
.icon-Woonkamer:before {
  content: "\e917";
}
.icon-Zijtuin-links:before {
  content: "\e918";
}
.icon-Zijtuin-rechts:before {
  content: "\e919";
}
.icon-Zolder:before {
  content: "\e91a";
}
